import { useEffect, useState } from "react";
import he from 'he'; // Importa la biblioteca 'he' para decodificar las descripciones

let BaseDeDatos = null;  // Variable para almacenar los datos
let ubicaciones = []; // Variable global para almacenar ubicaciones únicas
let tiposDePropiedades = []; // Nueva variable global para almacenar tipos de propiedades únicos
const maxPP = 20;// eslint-disable-next-line 

const fetchAndFormatData = async () => {
  try {
    const inm = 'SSE';
    const apiK = 'OXT0YCM6CC0CCMVUF44P58XTT';
    const url = 'https://xintel.com.ar/api/';
    let page = 0; // Comienza en la página 1
    let allFichas = []; // Arreglo para almacenar todas las fichas
    const allInmuebleIds = []; // Nuevo array para almacenar los inmueble_id
    while (true) {
      const queryParams = {
        json: 'resultados.fichas',
        inm,
        apiK,
        tipo_operacion: '',
        tipo_inmueble: '',
        page,
        rppagina: maxPP // Puedes ajustar esto según el número deseado por página
      };

      const query = new URLSearchParams(queryParams).toString();
      const apiUrl = `${url}?${query}`;

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const fichas = result?.resultado?.fichas || [];

      if (fichas.length === 0) {
        page++;
        break; 
      } else {
        // Formatear cada ficha y añadirla a allFichas

        const formattedFichas = fichas.map((property) => {
          const tipo = property.tipo || '';
          const ambientes = property.ambientes_num || '';
          const superficieTotal = property.in_sto || 'N/A';

          // Ajuste aquí para determinar la ubicación correctamente
          let ubicacion = property.in_bar !== "" ? property.in_bar : property.in_loc;

          // Almacenar ubicaciones únicas globalmente
          if (!ubicaciones.includes(ubicacion)) {
            ubicaciones.push(ubicacion);
          }

          // Almacenar tipos de propiedades únicos globalmente
          if (!tiposDePropiedades.includes(tipo)) {
            tiposDePropiedades.push(tipo);
          }

          const operacion = property.in_ope === 'V' ? 'VENTA' : 'ALQUILER';

          const metaDescripcion = `${tipo} en ${operacion} de ${ambientes} ambientes en ${ubicacion} con ${superficieTotal} m².`;

          allInmuebleIds.push(property.inmueble_id);
          return {
            nombre: property.titulo,
            imagen: property.img_princ,
            direccion_completa: property.direccion_completa,
            precio: property.precio,
            venta_precio: property.venta_precio,
            venta_moneda: property.venta_moneda,
            alquiler_precio: property.alquiler_precio,
            alquiler_moneda: property.alquiler_moneda,
            ambientes_2: property.ambientes,
            tipo: property.tipo,
            estado: property.estado,
            operacion: property.in_ope === 'V' ? 'ventas' : 'alquileres',
            ambientes: property.ambientes_num,
            dormitorios: property.dormitorios,
            id: property.in_num,
            superficieTotal: property.in_sto,
            in_sul: property.in_sul,
            sup_semicubierta: property.sup_semicubierta,
            descripcion: property.in_obs,
            zonaCiudad: property.zonaCiudad,
            ubicacion,  // Aquí se asigna la ubicación determinada dinámicamente
            in_cubierto: property.in_cub,
            disponibilidad: property.in_esi,
            baños: property.in_bao,
            autos: property.in_coc,
            titulo: `${property.tipo} en ${property.in_ope === 'V' ? 'VENTA' : 'ALQUILER'} ${ubicacion}`,
            metaDescripcion
          };
        });

        // Agregar las fichas de la página actual a allFichas
        allFichas = [...allFichas, ...formattedFichas];

        page++; // Incrementar a la siguiente página
      }
    }

    // Imprimir la lista de inmueble_id al final
    // console.log("Lista de inmueble_id:", allInmuebleIds);

    return allFichas;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const GetDatos = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ datos: [] });  // Inicializar con un objeto que tiene un array vacío

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!BaseDeDatos) {
          await fetchAndFormatUbi(); // Fetch ubicacion first
          await fetchAndFormatTipos(); // Fetch tipos de propiedades
          const fetchedData = await fetchAndFormatData(); // Then fetch the main data
          BaseDeDatos = { datos: fetchedData };  // Store the main data in an object with a 'datos' property
        }

        setData(BaseDeDatos);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  
  return { loading, datos: data.datos };
};

const fetchAndFormatUbi = async () => {
  try {
    await fetchAndFormatData(); // Llamamos a fetchAndFormatData para asegurar que las ubicaciones estén actualizadas
    return ubicaciones.map((ubicacion) => ({
      descripcion: he.decode(ubicacion.trim())
    }));
  } catch (error) {
    console.error('Error al obtener las ubicaciones:', error);
    throw error;
  }
};

const fetchAndFormatTipos = async () => {
  try {
    await fetchAndFormatData(); // Llamamos a fetchAndFormatData para asegurar que los tipos estén actualizados
    return tiposDePropiedades.map((tipo) => ({
      descripcion: he.decode(tipo.trim())
    }));
  } catch (error) {
    console.error('Error al obtener los tipos de propiedades:', error);
    throw error;
  }
};

export { GetDatos, fetchAndFormatUbi, fetchAndFormatTipos };