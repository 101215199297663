import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ItemDetails from '../ItemDetails';
import "./ItemList.css";

const ItemList = ({ productos }) => {
  
  return (
    <main className="container">
      <div className='album'>
   
        {productos.length > 0 ? (
          <div key={productos.length} className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 card-outer">
            {productos.map((prod) => (
              <ItemDetails key={prod.id} {...prod} />
            ))}
          </div>
        ) : (
          <div className="text-center mt-5">
            <div className="alert alert-warning" role="alert">
              No se encontraron propiedades con los criterios de búsqueda seleccionados.
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ItemList;
